import * as React from "react"
import {useState} from "react"
import {Accent, Layout} from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";


export default function IndexRoute() {
    const query = useStaticQuery(graphql`
  query Query {
  allGalleryJson {
    nodes {
      alt
      title
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
}
`).allGalleryJson.nodes;
    query.forEach(i => i.url = i.image.publicURL);
    const [showLightbox, setShowLightbox] = useState(false)
    const [imageIndex, setImageIndex] = useState(0)

    return (
        <>
            <Layout>
                <Seo title="Gallery"/>

                <Section padding={'pb-12'}>
                    <h1 className={'text-2xl font-semibold text-gray-800 mb-4 w-fit'}>Gallery {Accent()}</h1>
                    <div className={'pb-10 text-gray-800'}>Hover over the images or click to view full screen.</div>
                    <div className={"grid inline-grid gap-5 lg:gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-3"}>
                        {query.map((image, i) => {
                            return (
                                <div
                                    className={'group relative hover:cursor-pointer hover:scale-105 transition drop-shadow-sm hover:drop-shadow-md ease-linear duration-200'}
                                    key={i}
                                >
                                    <GatsbyImage
                                        className={'object-cover object-center h-60 w-100 object-center w-full shadow self-center'}
                                        image={getImage(image.image)}
                                        draggable={false}
                                        alt={image.title}
                                    />
                                    <div
                                        className={'absolute hidden group-hover:block h-full bottom-0 p-1 font-medium bg-black/75 w-full transition duration-200'}>

                                        <div className={"h-full grid items-center text-center z-10"} data-index={i}
                                             onClick={(e) => {
                                                 let index = parseInt(e.target["dataset"].index);
                                                 console.log(index)
                                                 if (Number.isInteger(index)) {

                                                     try {
                                                         setImageIndex(index);
                                                         setShowLightbox(true);
                                                     } catch (err) {
                                                         console.log(err);
                                                         setImageIndex(0);
                                                         setShowLightbox(false);
                                                     }
                                                 }

                                             }}>
                                            <div
                                                className={'block text-xl sm:text-lg font-bold text-white z-0'}
                                                data-index={i}>{image.title}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Section>
            </Layout>
            {showLightbox &&
                <Lightbox images={query} startIndex={imageIndex} onClose={() => setShowLightbox(false)}/>
            }
        </>
    );
}